import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UUID} from 'angular2-uuid';
import {HoleList} from './drill';
import {Specification} from '../specification/PCBSpecification';
import {delay, filter, first, flatMap, map, retryWhen, take, tap} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {PcbFileUploadService} from '../files/pcb-file-manager/pcb-file-upload.service';
import {AssemblyService} from '../../assembly.service';
import {
    DrillFileMsg,
    DuplicatePCB,
    FileSetMsg,
    FormatChangedMsg,
    LayerFile,
    LayerRendered,
    Outline, OutlineCandidateSetMsg,
    PCB,
    PCBState, PCBV2,
    SetOutline
} from '../../../../../pcb-common/pcb/pcb';
import {
    Assembly,
    AssemblyWithReference,
    SharedAssemblyReference,
    StreamMessage
} from '../../../../../pcb-common/assembly/assembly';
import {LoginService} from '../../../../../common/auth/login.service';
import {EnvironmentService} from '../../../../../common/env/environment.service';
import {ProgressBarService} from '../../../../../common-ui/progressbar/progress-bar.service';

@Injectable({
    providedIn: 'root'
})
export class Pcb2Service implements OnDestroy {
    public static PCB_ENDPOINT = '/ems/pcb';
    public static RENDER_ENDPOINT = '/ems/renderer';

    private assemblyUUID: UUID;
    private versionUUID: UUID;

    private currentPCB: BehaviorSubject<PCB>;
    private _outline: BehaviorSubject<Outline> = new BehaviorSubject(null);
    private _drills: BehaviorSubject<HoleList[]> = new BehaviorSubject(null);
    private ws: WebSocket;

    private renderingTopic = new Subject<LayerRendered>();

    constructor(
        private _httpClient: HttpClient,
        private _loginService: LoginService,
        private _progress: ProgressBarService,
        private _pcbFiles: PcbFileUploadService,
        private _assService: AssemblyService,
        private logger: NGXLogger,
        private env: EnvironmentService
    ) {
        this.currentPCB = new BehaviorSubject(new PCB());
        this.currentPCB.subscribe(pcb => {
            // this.loadDrills()
        });
    }

    ngOnDestroy(): void {
        this.logger.debug('PCB2 Service is Destroyed!');
        this.closeCurrentWebSocket()
    }

    public getGraphicUpdates(): Observable<LayerRendered> {
        return this.renderingTopic.asObservable();
    }

    public getDrills(): Observable<HoleList[]> {
        return this._drills.pipe(filter(x => !!x));
    }

    public getOutline(): Observable<Outline> {
        return this._outline.asObservable();
    }

    private loadDrills(): void {
        this._httpClient.get<HoleList[]>(this.env.api('/ems/pcb', [this.assemblyUUID.toString(), 'versions', this.versionUUID.toString(), 'drills'])).subscribe(holes => {
            this._drills.next(holes)
        })
    }

    public getOutlineCandidates(withGraphic: boolean): Observable<Outline[]> {
        return this._httpClient.get<Outline[]>(
            this.env.environment.api +
            Pcb2Service.PCB_ENDPOINT +
            '/' +
            this.assemblyUUID +
            '/versions/' +
            this.versionUUID +
            '/outlines?withGraphic=true',
        )
    }

    public getPCBObservable(): Observable<PCB> {
        return this.currentPCB.asObservable();
    }

    public getPCBState(): Observable<PCBState> {
        return this._assService.getAssemblySubject().pipe(
            flatMap(ass => this.getOutline().pipe(map(outline => {
                const pcbState = new PCBState();
                if (ass.currentVersion.filesLocked) {
                    pcbState.locked = true;
                }
                return pcbState;
            }))));
    }


    public getCurrentPCB(): Observable<PCB> {
        return this.currentPCB.pipe(filter(pcb => pcb != null && pcb.assembly != null), first());
    }

    public resetPCB(): void {
        this.currentPCB.next(new PCB());
        this._drills.next(null);
        this._outline.next(null);
    }

    public initPCB(assemblyWithReference: AssemblyWithReference, version?: UUID): void {
        if (assemblyWithReference.isShared) {
            const ref = assemblyWithReference.reference as SharedAssemblyReference
            this.doInitSharedPCB(ref.id)
        } else {
            if (version) {
                this.doInitPCB(assemblyWithReference.assembly.id, version)
            } else {
                this.doInitPCB(assemblyWithReference.assembly.id, assemblyWithReference.assembly.currentVersion.id)
            }
        }
    }

    public fetchPCB2(pcb: AssemblyWithReference): Observable<PCBV2> {
        if(pcb.isShared){
            return this._httpClient.get<PCBV2>(this.env.api(Pcb2Service.PCB_ENDPOINT, ['v2', 'shares', pcb.reference.getReferenceIdentifier().toString()]))
        }else{
            return this._httpClient.get<PCBV2>(this.env.api(Pcb2Service.PCB_ENDPOINT, ['v2', 'pcbs', pcb.reference.getReferenceIdentifier().toString()]))
        }
    }

    public doInitSharedPCB(share: UUID): void {
        this._httpClient.get<PCB>(this.env.api(Pcb2Service.PCB_ENDPOINT, ['shares', share.toString(), 'pcb']))
            .pipe(
                retryWhen(errors => errors.pipe(
                    delay(1000),
                    take(10)
                )),
            )
            .subscribe(pcb => {
                this.assemblyUUID = pcb.assembly;
                this.versionUUID = pcb.version;

                this.currentPCB.next(pcb);
                if (pcb.outline) {
                    this._outline.next(pcb.outline);
                }
                this._drills.next(null);
            });
    }

    public doInitPCB(assembly: UUID, version: UUID): void {
        this.logger.debug('loading ' + assembly + ' in version ' + version);
        this.assemblyUUID = assembly;
        this.versionUUID = version;
        // this._progress.show();
        const that = this;
        this.loadPCB().subscribe(
            pcb => {

                // this.logger.debug('pcb websocket', pcb);
                this.subscribePcbSocket(this._loginService.getCurrentAuthToken());
                this.currentPCB.next(pcb);
                if (pcb.outline) {
                    this._outline.next(pcb.outline);
                }
                this._drills.next(null);
                pcb.holes.forEach(list => {
                    this.getHolelist(list).subscribe(l => {
                        const lst = this._drills.getValue();
                        lst.push(l);
                        this._drills.next(lst);
                    });
                });

                this.loadDrills()
            },
            error => {
                this.logger.debug('error in loadPCB');
                this.logger.debug(error);
                this._progress.hide();
            }
        );
    }

    public updateMeta(meta: any): Observable<any> {
        return this._assService.getCurrentAssembly()
            .pipe(
                flatMap(ass => {
                    return this._httpClient.put<any>(this.env.api(Pcb2Service.PCB_ENDPOINT, [ass.id, 'versions',
                        ass.currentVersion.id, 'meta']), meta)
                        .pipe(tap(newMeta => {
                            const pcb = this.currentPCB.getValue();
                            pcb.metaInfo = newMeta;
                            this.currentPCB.next(pcb);
                        }));
                }));
    }

    public subscribePcbSocket(token: String): void {
        this.closeCurrentWebSocket();

        this.ws = new WebSocket(
            this.env.environment.ws +
            Pcb2Service.PCB_ENDPOINT +
            '/' +
            this.assemblyUUID +
            '/versions/' +
            this.versionUUID +
            '/updates?k=' +
            token
        );

        const that = this;
        this.ws.onclose = evt => {
            if (!evt.wasClean) {
                this.subscribePcbSocket(token)
            }
        }
        this.ws.onmessage = event => {
            const data: StreamMessage = JSON.parse(event.data);

            const pcb = that.currentPCB.value;
            if (data.t === 'outline_candidate_set') {
                const f = data.m as OutlineCandidateSetMsg;
                pcb.outline = f.outline;
                that.currentPCB.next(pcb);
                that._outline.next(f.outline);
            } else if (data.t === 'file') {
                const f = data.m as FileSetMsg;
                let updated = false;
                pcb.files.map(file => {
                    if (file.id === f.file.id) {
                        updated = true;
                        return f.file;
                    } else {
                        return file;
                    }
                });

                if (!updated) {
                    pcb.files.push(f.file);
                }

            } else if (data.t === 'render') {
                const f = data.m as LayerRendered;
                that.logger.debug('pcb-socket >>> new layer was rendered in backend', f, 'pcb service ', this);
                // that._graphics.addGraphic(f.id, f.data);
                that.renderingTopic.next(f);
            } else if (data.t === 'format') {
                const f = data.m as FormatChangedMsg;
                that.logger.debug('format update ', f);

                if (pcb.outline) {
                    if (f.file.id === pcb.outline.id) {
                        that._outline.next(pcb.outline);
                    }
                }
            } else if (data.t === 'drill') {
                const f = data.m as DrillFileMsg;
                that.logger.debug('drill update ', f);

                that.getHolelist(f.drillFile).subscribe(l => {
                    const x = that._drills.getValue();
                    x.push(l);
                    that._drills.next(x);
                });
            }
        };
    }

    private closeCurrentWebSocket() {
        if (this.ws) {
            this.ws.onclose = evt => {
            }
            this.ws.close();
        }
    }

    public setDefaultSpecification(spec: Specification): Observable<any> {
        this.logger.debug('set default spec');
        return this._httpClient.put<any>(
            this.env.environment.api +
            Pcb2Service.PCB_ENDPOINT +
            '/' +
            this.assemblyUUID +
            '/versions/' +
            this.versionUUID,
            {'defaultSpecification': spec.id}
        ).pipe(
            tap(newPCB => {
                this.currentPCB.next(newPCB);
            }));
    }

    public setOutlineForPcb(candidate: UUID, ass: AssemblyWithReference): Observable<any> {
        const setOutlineCommand = new SetOutline(candidate);
        return this._httpClient.post<any>(
            this.env.environment.api +
            Pcb2Service.PCB_ENDPOINT +
            '/' +
            ass.assembly.id +
            '/versions/' +
            ass.assembly.currentVersion.id +
            '/outline',
            setOutlineCommand
        );
    }

    invertFileTo(value: Assembly, f: LayerFile, inverted: boolean): Observable<boolean> {
        return this._pcbFiles.invertFileTo(value, f, inverted).pipe(map(p => {
            const pcb = this.currentPCB.getValue();
            pcb.files = pcb.files.map(fl => {
                if (fl.name === f.name) {
                    fl.inverted = inverted;
                }
                return fl;
            });

            this.currentPCB.next(pcb);
            return inverted;
        }));

    }

    public refreshPCBForAssembly(ass: Assembly): Observable<PCB> {
        return this._httpClient.get<PCB>(this.env.api(Pcb2Service.PCB_ENDPOINT, [ass.id, 'versions', ass.currentVersion.id])).pipe(
            tap(
                p => this.currentPCB.next(p)
            )
        );
    }

    public refreshPCB(): Observable<PCB> {
        const currentPCB = this.currentPCB.getValue();
        return this._httpClient.get<PCB>(this.env.api(Pcb2Service.PCB_ENDPOINT, [currentPCB.assembly.toString(), 'versions', currentPCB.version.toString()])).pipe(
            tap(
                p => this.currentPCB.next(p)
            )
        );
    }

    public loadPCBForAssembly(ass: Assembly): Observable<PCB> {
        return this._httpClient.get<PCB>(this.env.api(Pcb2Service.PCB_ENDPOINT, [ass.id, 'versions', ass.currentVersion.id]));
    }

    public getPCBMetaForAssembly(ass: Assembly): Observable<any> {
        return this._httpClient.get<PCB>(this.env.api(Pcb2Service.PCB_ENDPOINT, [ass.id, 'versions', ass.currentVersion.id, 'meta']));
    }

    public getPCBMetaForAssemblyID(assId: string, versionId: string): Observable<any> {
        return this._httpClient.get<PCB>(this.env.api(Pcb2Service.PCB_ENDPOINT, [assId, 'versions', versionId, 'meta']));
    }

    public getPCBMetaForShare(share: string): Observable<any> {
        return this._httpClient.get<PCB>(this.env.api(Pcb2Service.PCB_ENDPOINT, ['shares', share, 'pcb', 'meta']));
    }

    public getPcbDuplicates(pcb: UUID): Observable<DuplicatePCB[]> {
        return this._httpClient.get<DuplicatePCB[]>(this.env.api(Pcb2Service.PCB_ENDPOINT, ['v2', 'pcbs', pcb.toString(), 'duplicates']));
    }

    private getHolelist(path: string): Observable<HoleList> {
        return this._httpClient.get<HoleList>(
            this.env.environment.files + '/assembly/' + this.assemblyUUID + '/versions/' + this.versionUUID + '/' + path
        );
    }

    private loadPCB(): Observable<PCB> {
        return this._httpClient.get<PCB>(this.env.api(Pcb2Service.PCB_ENDPOINT, [this.assemblyUUID.toString(), 'versions', this.versionUUID.toString()]))
            .pipe(
                retryWhen(errors => errors.pipe(
                    delay(1000),
                    take(10)
                )),
            );
    }
}