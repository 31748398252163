<!-- Navigation -->
<fuse-vertical-navigation
    class="dark print:hidden bg-primary-900"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.items"
    [opened]="!isScreenSmall">

    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex content-center pt-5 px-2 flex-grow">
                <img
                    class="h-auto content-center"
                    src="assets/images/logos/stackrate-white.png">
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <!--                <notifications></notifications>-->
                <!--                <user [showAvatar]="false"></user>-->
            </div>
        </div>
        <!-- User -->
        <!-- div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img
                    class="w-full h-full rounded-full"
                    [src]="profileImage | async"
                    alt="User avatar">
            </div>
        </div-->
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <!-- Footer -->
        <!-- div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
            <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
        </div -->
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>



</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
